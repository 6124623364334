import {useMemo} from "react";
import {Link as ReactRouterLink, useLocation} from 'react-router-dom'
import {Link as ChakraLink} from '@chakra-ui/react'

const MenuLink = ({address, children, showActive, icon, ...props}) => {
    const location = useLocation();

    const isActive = useMemo(() => {
        return location.pathname === address && showActive;
    }, [location.pathname, showActive]);

    return (
        <ChakraLink as={ReactRouterLink}
                    to={address} {...props} 
                    bg={isActive ? 'rgba(255, 255, 255, 0.05)' : 'transparent'}>
            {children}
        </ChakraLink>
    )
};

export default MenuLink;