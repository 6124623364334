import {Flex, Box, Icon} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import MenuLink from "./MenuLink";
import {Fragment} from "react";

const WebMenu = ({routes}) => {
    const navigate = useNavigate();

    const handleNavigate = (route) => {
        navigate(route);
    };

    return (
        <Flex py={4} justifyContent="center"
              display={{base: 'none', sm: 'flex'}}>
            <Flex w="7xl" alignItems="center">
                <Box w="1px" h="100%" bg="secondary" />
                {routes.map((item, index) => (
                    <Fragment key={index}>
                        {index !== 0 && (
                            <Box w="1px" h="100%" bg="secondary" />
                        )}
                        <Flex flex="1" alignItems="center" justifyContent="center">
                            <MenuLink
                                onClick={() => handleNavigate(item.route)}
                                address={item.route}
                                _hover={{textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)', color: 'green'}}
                                fontSize={14}
                                color="white"
                                showActive
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                textAlign="center"
                                w={'100%'}
                                py={2}
                            >
                                {item.icon && <Icon as={item.icon} mr={2} color={'green'} />}
                                {item.label}
                            </MenuLink>
                        </Flex>
                    </Fragment>
                ))}
                <Box w="1px" h="100%" bg="secondary" />
            </Flex>
        </Flex>
    );
};

export default WebMenu;