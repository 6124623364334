const colors = {
    primary: "#011938",
    secondary: "#496C99",
    white: "#FFFFFF",
    green: "#2FEA52",
    gray4: '#BDBDBD',
    gray8: '#E5E5E5',
    input: '#F0F9FD',
    separator: '#DEDEDE',
    textSecondary: '#9495A9',
    textPrimary: '#323352',
}

export default colors;