import {Icon} from "@chakra-ui/react";

const CanisterIcon = (props) => {
    return (
        <Icon {...props}>
            <svg viewBox="0 0 10 12" fill="none">
                <path d="M0.891304 6C0.891304 3.1005 3.34401 0.75 6.36957 0.75H9.5V12H0.891304V6Z" fill="#011938"/>
                <path d="M0.5 1.84039L2.32423 0.374979L4.08384 2.3867L2.25961 3.85212L0.5 1.84039Z" fill="#011938"/>
                <path d="M5.19565 0H8.32609V2.625H5.19565V0Z" fill="#011938"/>
            </svg>
        </Icon>
    )
}

export default CanisterIcon;