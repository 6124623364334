import {Button, Icon, useDisclosure, Text, Flex,} from "@chakra-ui/react";
import {useRef} from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
} from '@chakra-ui/react'
import {TbMenu2} from "react-icons/tb";
import {MdClose} from "react-icons/md";
import MenuLink from "./MenuLink";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../providers/AuthProvider";

const MobileMenu = ({routes}) => {
    const {user, logout} = useAuth();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const buttonRef = useRef(null);
    const navigate = useNavigate();

    const handleNavigate = (route) => {
        navigate(route);
        onClose();
    }

    const handleLogin = () => {
        navigate('/login');
        onClose();
    }

    const handleLogout = () => {
        navigate('/');
        onClose();
    }

    return (
        <Flex display={{base: 'flex', sm: 'none'}}>
            <Button ref={buttonRef} bg={'transparent'} onClick={onOpen} p={0}>
                <Icon as={TbMenu2} color={'white'} boxSize={6} />
            </Button>
            <Drawer
                isOpen={isOpen}
                size={'xs'}
                placement='left'
                onClose={onClose}
                finalFocusRef={buttonRef}
                closeOnOverlayClick
            >
                <DrawerOverlay/>
                <DrawerContent bg={'primary'} color={'white'} px={0} w={'65vw !important'}>
                    <DrawerHeader display="flex" gap="8px" fontSize={16} fontWeight={400} alignItems={'center'}>
                            <Icon as={MdClose} color={'white'} boxSize={6} onClick={onClose}/>
                            Menu
                    </DrawerHeader>

                    <DrawerBody display={'flex'} flexDirection={'column'} px={0}>
                        {routes?.filter(item => user || item.type === 'public').map((item, index) => {
                            return <MenuLink key={index}
                                     onClick={() => handleNavigate(item.route)}
                                     address={item.route}
                                     _hover={{ textDecoration: "none", bg: 'rgba(255, 255, 255, 0.05)' }}
                                     showActive
                                     fontSize={14}
                                     py={3} px={6}>
                                    {item.label}
                                </MenuLink>
                        })}
                    </DrawerBody>
                    <DrawerFooter display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    {user ? (
			            <>
                            <Text opacity={0.6} fontSize={14}>Zalogowany użytkownik:</Text>
                            <Text fontSize={14} textOverflow="ellipsis" mb={4} fontFamily={"'Ubuntu', sans-serif"}>
                                {user?.email}
                            </Text>
                            <Button color="white" variant="solid" width="100%" fontSize={14} fontWeight={500} bg={'secondary'} borderRadius={32} onClick={handleLogout}>
                                Wyloguj się
                            </Button>
                        </>
                    ) : (
                            <Button color="white" variant="solid" width="100%" fontSize={14} fontWeight={500}
                                    bg={'secondary'} borderRadius={32} onClick={handleLogin}>
                                Zaloguj się
                            </Button>
                    )}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}

export default MobileMenu;
