import {apiClient} from "../apiClient";
import QueryHelpers from "../../components/helpers/QueryHelpers";

const realize = (offerId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const form = new FormData();
    form.append('offerId', offerId);
    form.append('userId', user.id);

    return apiClient.post('/transactions/realize', form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user.bearerToken}`
        }
    });
}
const getMyTransactions = (carBrand, status) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const params = {
        pageSize: 30,
        page: 1,
        sortOrder: "desc",
        carBrand: carBrand,
        userId: user?.id,
        status: status
    }

    const query = QueryHelpers.buildQueryString(params);
    return apiClient.get(`/transactions?${query}`);
}

export default {
    realize,
    getMyTransactions
}
