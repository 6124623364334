import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider";
import logo from "../../assets/images/logo_dark.png";
import TextInput from "../../components/textInput/TextInput";
import LinkTo from "../../components/typography/Link";
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Text,
    Stack,
    Image,
} from '@chakra-ui/react';
import {useEffect, useMemo, useState} from "react";
import {apiClient} from "../../client/apiClient";

const RegisterPage = () => {
    const {t} = useTranslation("RegisterPage");
    const {register} = useAuth();
    const [carBrands, setCarBrands] = useState([])

    useEffect(() => {
        apiClient.get('/carbrands').then(response => {
            setCarBrands(response.data);
        })
    }, []);
    const handleSubmit = (values, {setSubmitting}) => {
        const carBrand = carBrands.findIndex(c => c === values.carBrand);
        register({...values, carBrand: carBrand});
        setSubmitting(false);
    }
    const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(t('EMAIL_FORMAT'))
            .required(t('EMAIL_REQUIRED')),
        phone: Yup.string()
            .matches(phoneRegExp, t('PHONE_FORMAT'))
            .required(t('PHONE_REQUIRED')),
        registrationNumber: Yup.string()
            .required(t('REGISTRATION_NUMBER_REQUIRED')),
        password: Yup.string()
            .min(6, t('PASSWORD_TOO_SHORT'))
            .required(t('PASSWORD_REQUIRED')),
        confirmPassword: Yup.string()
            .required(t('PASSWORD_REPEAT'))
            .oneOf([Yup.ref('password'), null], t('PASSWORD_DIFFERENT'))
    });

    return (
        <Box
            minW={{base: '100%', md: 'xl'}}
            mx="auto"
            px={{base: 4, sm: 6}}
            py={8}>

            <Stack spacing={4} align="center" mb={8}>
                <Image src={logo} w={160} pb={6}/>
                <Text
                    textAlign="center"
                    lineHeight={{base: '16px', sm: 'sm'}}
                    fontSize={{base: 14, sm: 'lg'}}
                    color="black"
                    width={'50%'}
                >
                    {t('CLIENT_REGISTER_INFO')}
                </Text>
                <Text
                    textAlign="center"
                    lineHeight={{base: '21px', sm: 'sm'}}
                    fontSize={{base: 14, sm: 'lg'}}
                    fontFamily={'Poppins'}
                    fontWeight={400}
                    color="#9495A9"
                    width={'60%'}
                >
                    {t('CLIENT_LOGIN_INFO')}
                </Text>
            </Stack>
            <Box maxW={{base: '100%', sm: 'xl'}} mx={'auto'}>
            <Formik
                initialValues={{email: '', password: ''}}
                isInitialValid={false}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched, setFieldValue}) => (
                    <Form>
                        <Box display="flex" flexDirection="column" rowGap={8}>
                            <Box display="flex" flexDirection="column" gap={4} textAlign={'left'}>
                                <TextInput
                                    label={t("EMAIL_LABEL")}
                                    name="email"
                                    type="email"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("EMAIL_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />
                                <TextInput
                                    label={t("PHONE_LABEL")}
                                    name="phone"
                                    type="phone"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("PHONE_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />
                                <TextInput
                                    label={t("REGISTRATION_NUMBER")}
                                    name="registrationNumber"
                                    type="text"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("REGISTRATION_NUMBER_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />

                                <TextInput
                                    label={t("PASSWORD_LABEL")}
                                    name="password"
                                    type="password"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("PASSWORD_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />

                                <TextInput
                                    label={t("PASSWORD_CONFIRM_LABEL")}
                                    name="confirmPassword"
                                    type="password"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("PASSWORD_CONFIRM_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                            <Button
                                display={"flex"}
                                type="submit"
                                justifySelf="center"
                                isLoading={isSubmitting}
                                bg="green"
                                mb={4}
                                px={24}
                                borderRadius={40}
                                fontSize={{base: 16, sm: 'md'}}
                                color="primary"
                            >
                                {t('CLIENT_BUTTON')}
                            </Button>

                            <Text textAlign="center" fontSize={{base: 14, sm: 'sm'}} color="secondary">
                                {t("HAVE_ACCOUNT")} <LinkTo address="/login" color="primary" fontWeight={700}
                                                            textDecoration="none">{t("LOGIN")}</LinkTo>
                            </Text>
                        </Box>
                    </Form>
                )}
            </Formik>
            </Box>
        </Box>
    )
}

export default RegisterPage;
