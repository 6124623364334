import {
    Box,
    Collapse,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useMemo, useState} from "react";
import {HiOutlineSearch} from "react-icons/hi";
import {FaChevronDown} from "react-icons/fa";

const SelectInput = ({options, iconOption, contentWidth, onSelect, optionLabelKey, renderOption, ...props}) => {
    const {isOpen, onToggle, onClose} = useDisclosure();
    const [searchTerm, setSearchTerm] = useState("");
    const [value, setValue] = useState(null);

    const getOptionLabel = (option) => {
        if (typeof option === "string") return option;
        return option[optionLabelKey] || "Unknown";
    };

    const filteredOptions = useMemo(() => {
        if (searchTerm === "" || value) return options;
        return options.filter(option =>
            getOptionLabel(option).toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, options]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setSearchTerm(newValue);

        if (newValue === "") {
            setValue(null);
            onSelect(null);
        } else {
            setValue(null);
        }
        if (!isOpen) onToggle();
    };

    const handleOptionClick = (option) => {
        setSearchTerm(getOptionLabel(option));
        setValue(option);
        onSelect(option);
        onClose();
    };

    return (
        <Box w={contentWidth}>
            <InputGroup size='md'>
                <Input
                    id={'carBrands'}
                    value={searchTerm}
                    onChange={handleInputChange}
                    px={'2rem'}
                    variant="filled"
                    bg="input"
                    borderRadius={32}
                    fontSize={{base: 'sm', sm: 'md'}}
                    {...props}
                />
                <InputLeftElement w={'2rem'}>
                    {iconOption ? iconOption : <HiOutlineSearch/>}
                </InputLeftElement>
                <InputRightElement px={3} onClick={onToggle} cursor={'pointer'} justifyContent={'flex-end'}>
                    <FaChevronDown style={{
                        transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease'
                    }}/>
                </InputRightElement>
            </InputGroup>
            <Collapse in={isOpen} animateOpacity>
                <Box borderRadius={8} py={4} h={'10rem'} overflow={'scroll'} borderColor={'input'} borderWidth={1} bg={'white'}>
                    {filteredOptions.map((item, index) => (
                        <Text
                            key={index}
                            p={2}
                            fontWeight={400}
                            bg={(index + 1) % 2 === 0 && '#f6f6f6'}
                            onClick={() => handleOptionClick(item)} // Zaktualizuj wartość i zamknij listę
                            cursor="pointer"
                        >
                            {renderOption ? renderOption(item) : getOptionLabel(item)}
                        </Text>
                    ))}
                </Box>
            </Collapse>
        </Box>
    )
}

export default SelectInput;
