import {Box, Divider, Flex, Image, Text, useDisclosure, ScaleFade, Button} from "@chakra-ui/react";
import logo from "../../assets/images/logo_light.png";
import ReactCountryFlag from "react-country-flag";
import MobileMenu from "./MobileMenu";
import WebMenu from "./WebMenu";
import { HiSpeakerphone } from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider";
import {useCallback, useEffect} from "react";
import avatarPlaceholder from "../../assets/images/avatar_placeholder.png"
import {useNavigate} from "react-router-dom";

const ROUTES = [
    {
        label: 'Wszystkie rabaty',
        route: '/',
        icon: HiSpeakerphone,
        type: 'public'
    },
    {
        label: 'Twoje rabaty',
        route: '/my'
    },
    {
        label: 'Twój kanister',
        route: '/a'
    },
    {
        label: 'Twoje tankowanie',
        route: '/b'
    },
    {
        label: 'Ustawienia konta',
        route: '/c'
    },
    {
        label: 'Kontakt',
        route: '/d',
        type: 'public'
    },
]

const Menu = () => {
    const {i18n} = useTranslation();
    const {user, logout} = useAuth();
    const {isOpen, onToggle} = useDisclosure();
    const navigate = useNavigate();

    const changeLanguageHandler = () => {
        const lang = i18n.language;
        i18n.changeLanguage(lang === "pl" ? "en" : "pl");
    }

    const currentFlag = useCallback(() => {
        const lang = i18n.language;
        return <ReactCountryFlag
            style={{cursor: 'pointer', width: 25}}
            countryCode={lang === "pl" ? "gb" : "pl"}
            svg
            onClick={changeLanguageHandler}/>
    }, [i18n.language, changeLanguageHandler]);

    const handleToggleUserMenu = () => {
        onToggle();
    }

    const handleNavigate = (route) => {
        navigate(route);
    }

    const handleLogin = () => {
        handleNavigate('/login');
    }

    const handleLogout = () => {
        logout();
        onToggle();
    }

    return (
        <Flex bg={'primary'} w={'100vw'} py={4} px={4} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <Flex w={{base: '100%', sm: '7xl'}} justifyContent={'space-between'} alignItems={'center'} py={4}>
                <Box display={'flex'} justifyContent="center" alignItems="center" columnGap={4}>
                    {currentFlag()}
                    <Image src={logo} h={{base: 16, sm: 12}} cursor={'pointer'} onClick={() => handleNavigate("/")}/>
                </Box>
                <Box position={'relative'} display={{base: 'none', sm: 'block'}}>
                {user ? (
                    <>
                        <Box display={'flex'} justifyContent="flex-end" alignItems="center" columnGap={4}
                             onClick={handleToggleUserMenu} cursor={'pointer'}
                             data-state="open"
                             _open={{
                                 animation: "fade-in 200ms ease-out",
                             }}
                             alignSelf={'flex-end'}>
                            <Image src={user?.avatarUrl ?? avatarPlaceholder} boxSize={9} fit="cover"
                                   borderRadius={"full"}/>
                            <Text color="white" fontSize={{base: 12, sm: 14}} textAlign={'left'}
                                  flexWrap={'wrap'}>{user?.companyName}</Text>
                        </Box>
                        <ScaleFade initialScale={0.9} in={isOpen}>
                            <Box py={2}
                                 px={6}
                                 bg={'#F6F6F6'}
                                 borderWidth={1}
                                 borderColor={'#F4F4F4'}
                                 cursor={'pointer'}
                                 onClick={handleLogout}
                                 position={'absolute'}
                                 borderRadius={4}
                                 zIndex={2}
                                 mt={2}
                                 right={0}>
                                Wyloguj
                            </Box>
                        </ScaleFade>
                    </>
                    ) : (
                    <Button
                        display={"flex"}
                        type="submit"
                        justifySelf="center"
                        bg="green"
                        mb={4}
                        px={20}
                        onClick={handleLogin}
                        borderRadius={40}
                        fontFamily={'Ubuntu'}
                        fontSize={{base: 16, sm: 'md'}}
                        fontWeight={700}
                        lineHeight={'24px'}
                        color="primary">
                        Zaloguj się
                    </Button>
                    )}
                </Box>
                <MobileMenu routes={ROUTES}/>
            </Flex>
            <Divider borderColor={'secondary'} />
            {user &&
                <WebMenu routes={ROUTES}/>
            }
        </Flex>
    )
}

export default Menu;
