import {Box, Image, Spinner} from "@chakra-ui/react";
import logo from "../../../assets/images/logo_light.png";

const Loader = () => {
    return (
        <Box display={'flex'} w={'100vw'} h={'100vh'} bg={'primary'} justifyContent={'center'} alignItems={'center'}
             flexDirection={'column'} rowGap={8}>
            <Image src={logo} h={16}/>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='transparent'
                color='white'
                size='lg'
            />
        </Box>
    )
}

export default Loader;