// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.25rem;
    width: 100%;
}
.textInput .label {
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    text-align: left;
    color: #323352;
}

.textInput input {
    background-color: #FFFFFF !important;
    outline: none;
    box-shadow: none;
    color: #323352;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}

.textInput input:focus {
    outline: none;
    box-shadow: none;
}

.textInput input:hover {
    background-color: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/textInput/textInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,aAAa;IAEb,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IAEb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".textInput {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    row-gap: 0.25rem;\n    width: 100%;\n}\n.textInput .label {\n    font-weight: 400;\n    font-size: 16px;\n    width: 100%;\n    text-align: left;\n    color: #323352;\n}\n\n.textInput input {\n    background-color: #FFFFFF !important;\n    outline: none;\n    -webkit-box-shadow: none;\n    box-shadow: none;\n    color: #323352;\n    font-weight: 400;\n    font-size: 14px;\n    width: 100%;\n}\n\n.textInput input:focus {\n    outline: none;\n    -webkit-box-shadow: none;\n    box-shadow: none;\n}\n\n.textInput input:hover {\n    background-color: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
