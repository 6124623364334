import './LoginPage.css'
import TextInput from "../../components/textInput/TextInput";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider";
import {
    Box,
    Button,
    Link,
    Text,
    Stack,
    Image
} from '@chakra-ui/react';
import * as Yup from 'yup';
import logo from '../../assets/images/logo_dark.png'
import {Formik, Form} from 'formik';
import LinkTo from "../../components/typography/Link";
import {Navigate, useNavigate} from "react-router-dom";

const LoginPage = () => {
    const {t} = useTranslation("LoginPage");
    const {login, user, loginError} = useAuth();
    const navigate = useNavigate();

    if(user) {
        return <Navigate to={"/"} replace  />
    }

    const handleSubmit = (values, {setSubmitting}) => {
        login(values.email, values.password);
    }

    const handleNavigate = (route) => {
        navigate(route);
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Niepoprawny format email')
            .required('Email jest wymagany'),
        password: Yup.string()
            .min(6, 'Hasło musi zawierać co najmniej 6 znaków')
            .required('Hasło jest wymagane'),
    });

    return (
        <Box
            minW={{base: '100%', sm: 'lg'}}
            mx="auto"
            px={{base: 4, sm: 6}}
            py={8}>

            <Stack spacing={4} align="center" mb={8}>
                <Image src={logo} w={160} pb={6}/>
                <Text
                    textAlign="center"
                    lineHeight={{base: '24px', sm: 'sm'}}
                    fontSize={{base: 16, sm: 'lg'}}
                    fontFamily={'Poppins'}
                    fontWeight={600}
                    color="#323352"
                    width={'50%'}
                >
                    {t('CLIENT_LOGIN_TO')}
                </Text>
                <Text
                    textAlign="center"
                    lineHeight={{base: '21px', sm: 'sm'}}
                    fontSize={{base: 14, sm: 'lg'}}
                    fontFamily={'Poppins'}
                    fontWeight={400}
                    color="#9495A9"
                    width={'60%'}
                >
                    {t('CLIENT_LOGIN_INFO')}
                </Text>
            </Stack>
            <Box maxW={{base: '100%', sm: 'lg'}} mx={'auto'}>
                <Formik
                    initialValues={{email: '', password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <Box display="flex" flexDirection="column" gap={4}>
                                <TextInput
                                    label={t("CLIENT_EMAIL")}
                                    name="email"
                                    type="email"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("CLIENT_EMAIL_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />

                                <TextInput
                                    label={t("PASSWORD")}
                                    name="password"
                                    type="password"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder="Wprowadź hasło"
                                    errors={errors}
                                    touched={touched}
                                />
                            </Box>
                            <Link color="secondary" py={2} fontSize={{base: 12, sm: 'sm'}} textAlign="right"
                                  display="block" onClick={() => handleNavigate("/reminder")}>
                                {t("PASSWORD_REMIND")}
                            </Link>

                            {loginError && (
                                <Text color="red.500" textAlign="center" mt={4}>
                                    {t(loginError)}
                                </Text>
                            )}

                            <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                                <Button
                                    display={"flex"}
                                    type="submit"
                                    justifySelf="center"
                                    isLoading={isSubmitting &&!loginError}
                                    bg="green"
                                    mb={4}
                                    px={24}
                                    borderRadius={40}
                                    fontSize={{base: 16, sm: 'md'}}
                                    color="primary"
                                >
                                    Zaloguj się
                                </Button>

                                <Text textAlign="center" fontSize={{base: 14, sm: 'sm'}} color="secondary">
                                    {t("NO_ACCOUNT")} <LinkTo address="/register" color="primary" fontWeight={700}
                                                              textDecoration="none">{t("REGISTER")}</LinkTo>
                                </Text>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default LoginPage;
