import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/fonts/fonts.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n.js"
import {ChakraProvider} from "@chakra-ui/react";
import Loader from "./components/pages/Loader/Loader";
import theme from "./components/theme"
import {BrowserRouter as Router} from "react-router-dom";
import AuthProvider from "./providers/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider theme={theme}>
        <Suspense fallback={<Loader/>}>
            <Router>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </Router>
        </Suspense>
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
