import React from 'react';
import {Box, Text, Button, Image} from '@chakra-ui/react';
import tank from "../../assets/images/tank.svg";

const FuelWidget = ({ volume, onActionClick, actionText }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bg="primary"
            color="white"
            fontFamily={'Poppins'}
            fontWeight={700}
            borderRadius="16px"
            mb="16px"
            p="16px"
            boxShadow="md"
            w={{ base: '100%', md: '402px' }}
        >
            <Box>
                <Text fontSize="14px" fontWeight={500} lineHeight="21px" mb={2}>
                    W twoim kanistrze znajduje się:
                </Text>
                <Text fontSize="32px" fontWeight="bold" lineHeight="48px">
                    {volume} l
                </Text>
                <Button
                    mt={8}
                    bg="#FFFFFF"
                    color="#323352"
                    borderRadius="full"
                    onClick={onActionClick}
                    fontFamily={'Ubuntu'}
                    fontSize="16px"
                    lineHeight="24px"
                    py="12px"
                    px={{ base: '28px', md: '36px' }}
                >
                    {actionText || 'Zaplanuj tankowanie'}
                </Button>
            </Box>
            <Image src={tank} h="130px"/>
        </Box>
    );
};

export default FuelWidget;
