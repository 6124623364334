const carLogos = {
    AlfaRomeo: require('./AlfaRomeo.png'),
    Audi: require('./Audi.png'),
    BMW: require('./BMW.png'),
    Chevrolet: require('./Chevrolet.png'),
    Citroen: require('./Citroen.png'),
    Dacia: require('./Dacia.png'),
    Fiat: require('./Fiat.png'),
    Ford: require('./Ford.png'),
    Honda: require('./Honda.png'),
    Hyundai: require('./Hyundai.png'),
    Kia: require('./Kia.png'),
    Mazda: require('./Mazda.png'),
    Mercedes: require('./Mercedes.png'),
    MiniCooper: require('./MiniCooper.png'),
    Mitsubishi: require('./Mitsubishi.png'),
    Nissan: require('./Nissan.png'),
    Opel: require('./Opel.png'),
    Peugeot: require('./Peugeot.png'),
    Renault: require('./Renault.png'),
    Rover: require('./Rover.png'),
    Saab: require('./SAAB.png'),
    Seat: require('./Seat.png'),
    Skoda: require('./Skoda.png'),
    Subaru: require('./Subaru.png'),
    Suzuki: require('./Suzuki.png'),
    Toyota: require('./Toyota.png'),
    Volkswagen: require('./Volkswagen.png'),
};

export default carLogos;
