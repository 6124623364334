import {Flex} from "@chakra-ui/react";
import Menu from "./components/layout/Menu";
import AllOffersPage from "./pages/AllOffersPage/AllOffersPage";
import Footer from "./components/layout/footer/Footer";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import MyOffersTabs from "./pages/MyOffersTabs/MyOffersTabs";
import PasswordReminderPage from "./pages/PasswordReminderPage/PasswordReminderPage";

function App() {

    return (
            <Flex minH={'100vh'}
                  flexDirection={'column'}
                  alignItems={'center'}>
                <Menu/>
                <Flex flexGrow={1} bg={'#F1F1F1'} w={'100%'} justifyContent={'center'} p={4}>
                    <Flex w={{base: '100%', sm: '7xl'}} alignItems={'flex-start'}>
                        <Routes>
                            <Route path={"/"} element={<AllOffersPage />}/>
                            <Route path={"/my"} element={<MyOffersTabs />}/>
                            <Route path={"/login"} element={<LoginPage />}/>
                            <Route path={"/register"} element={<RegisterPage />}/>
                            <Route path={"/reminder"} element={<PasswordReminderPage />}/>
                        </Routes>
                    </Flex>
                </Flex>
                <Footer />
            </Flex>
    );
}

export default App;
