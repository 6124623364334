import {createContext, useContext, useEffect, useState} from "react";
import {apiClient} from "../client/apiClient";
import {useNavigate} from "react-router-dom";

export const AuthContext = createContext();
const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
    }, []);

    const login = (email, password) => {
        apiClient.post("/users/login", {email, password}).then(response => {
            if(response.data.isSuccess) {
                localStorage.setItem("user", JSON.stringify(response.data.value));
                setUser(response.data.value);
                navigate("/");
            } else {
                setLoginError(response.data.error?.message || "Unknown error occurred.");
            }
        })
        .catch(error => {
            setLoginError(error.response?.data?.error?.message || "Network error occurred.");
        })
    }

    const logout = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/");
    }

    const register = (values) => {
        const body = {
            email: values.email,
            registrationNumber: values.registrationNumber,
            password: values.password,
            phone: values.phone
        }

        apiClient.post('/users/register', body).then(res => {
            navigate("/login");
        })
    }

    const remind = (values) => {
        const body = {
            email: values.email
        }

        apiClient.post('/users/remind', body).then(res => {
            navigate("/login");
        })
    }

    return (
        <AuthContext.Provider
            value={{user, login, logout, register, remind, loginError}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext);
