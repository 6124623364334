import './textInput.css';
import React from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Textarea,
    InputLeftElement,
    InputGroup, InputRightElement
} from '@chakra-ui/react';
import {Field} from 'formik';
import {PhoneIcon} from "@heroicons/react/24/outline";

const TextInput = ({label, name, type = "text", placeholder, errors, touched, textarea = false, ...props}) => (
    <FormControl isInvalid={errors[name] && touched[name]} className={'textInput'}>
        <FormLabel color={'textPrimary'} fontSize={14} fontWeight={700} lineHeight="21px" mb={0} pb={1} htmlFor={name}>
            {label}
        </FormLabel>
            <Field
                as={textarea ? Textarea : Input}
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                _placeholder={{color: '#9495A9'}}
                variant="filled"
                bg="#FFFFFF"
                borderRadius={32}
                fontSize={{base: 'sm', sm: 'md'}}
                {...props}
            />
        <FormErrorMessage>{errors[name]}</FormErrorMessage>
    </FormControl>
);

export default TextInput;
