import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider";
import logo from "../../assets/images/logo_dark.png";
import TextInput from "../../components/textInput/TextInput";
import LinkTo from "../../components/typography/Link";
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Text,
    Stack,
    Image,
} from '@chakra-ui/react';

const PasswordReminderPage = () => {
    const {t} = useTranslation("PasswordReminderPage");
    const {remind} = useAuth();

    const handleSubmit = (values, {setSubmitting}) => {
        remind({...values});
        setSubmitting(false);
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Niepoprawny format email')
            .required('Email jest wymagany')
    });

    return (
        <Box
            minW={{base: '100%', md: 'xl'}}
            mx="auto"
            px={{base: 4, sm: 6}}
            py={8}>

            <Stack spacing={4} align="center" mb={8}>
                <Image src={logo} w={160} pb={6}/>
                <Text
                    textAlign="center"
                    lineHeight={{base: '16px', sm: 'sm'}}
                    fontSize={{base: 14, sm: 'lg'}}
                    color="black"
                    width={'50%'}
                >
                    {t('REMINDER_TITLE')}
                </Text>
                <Text
                    textAlign="center"
                    lineHeight={{base: '21px', sm: 'sm'}}
                    fontSize={{base: 14, sm: 'lg'}}
                    fontFamily={'Poppins'}
                    fontWeight={400}
                    color="#9495A9"
                    width={'60%'}
                >
                    {t('REMINDER_INFO')}
                </Text>
            </Stack>
            <Box maxW={{base: '100%', sm: 'xl'}} mx={'auto'}>
            <Formik
                initialValues={{email: ''}}
                isInitialValid={false}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting, errors, touched, setFieldValue}) => (
                    <Form>
                        <Box display="flex" flexDirection="column" rowGap={8}>
                            <Box display="flex" flexDirection="column" gap={4} textAlign={'left'}>
                                <TextInput
                                    label={t("EMAIL_LABEL")}
                                    name="email"
                                    type="email"
                                    fontWeight={500}
                                    borderRadius={32}
                                    placeholder={t("EMAIL_PLACEHOLDER")}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                            <Button
                                display={"flex"}
                                type="submit"
                                justifySelf="center"
                                isLoading={isSubmitting}
                                bg="green"
                                mb={4}
                                px={24}
                                borderRadius={40}
                                fontSize={{base: 16, sm: 'md'}}
                                color="primary"
                            >
                                {t("BUTTON")}
                            </Button>

                            <Text textAlign="center" fontSize={{base: 14, sm: 'sm'}} color="secondary">
                                {t("HAVE_ACCOUNT")} <LinkTo address="/login" color="primary" fontWeight={700}
                                                            textDecoration="none">{t("LOGIN")}</LinkTo>
                            </Text>
                        </Box>
                    </Form>
                )}
            </Formik>
            </Box>
        </Box>
    )
}

export default PasswordReminderPage;
