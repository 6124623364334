// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loginContainer {
    display: flex;
    padding: 4rem 0 4rem 0;
    flex-direction: column;
    align-items: center;
    max-width: 600px;

    h4 {
        color: #000;
        font-weight: 400;
        font-size: 18px;
    }

    .inputContainer {
        display: flex;
        row-gap: 1rem;
        flex-direction: column;
        padding-top: '2rem';
        width: 25vw;
    }
    
    @media (max-width: 992px) {
        .inputContainer {
            width: 90vw;
        }
    }
    
    .passwordRemind {
        text-align: right;
        color: #9495A9;
        font-weight: 400;
        font-size: 14px;
    }
    
    .buttonContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 2rem 0;
        
        .accountText {
            color: #323352;
            font-weight: 400;
            font-size: 16px;
            
            b {
                font-weight: 700;
            }
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/LoginPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;;IAEhB;QACI,WAAW;QACX,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,WAAW;IACf;;IAEA;QACI;YACI,WAAW;QACf;IACJ;;IAEA;QACI,iBAAiB;QACjB,cAAc;QACd,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,aAAa;QACb,eAAe;;QAEf;YACI,cAAc;YACd,gBAAgB;YAChB,eAAe;;YAEf;gBACI,gBAAgB;YACpB;QACJ;IACJ;AACJ","sourcesContent":["\n.loginContainer {\n    display: flex;\n    padding: 4rem 0 4rem 0;\n    flex-direction: column;\n    align-items: center;\n    max-width: 600px;\n\n    h4 {\n        color: #000;\n        font-weight: 400;\n        font-size: 18px;\n    }\n\n    .inputContainer {\n        display: flex;\n        row-gap: 1rem;\n        flex-direction: column;\n        padding-top: '2rem';\n        width: 25vw;\n    }\n    \n    @media (max-width: 992px) {\n        .inputContainer {\n            width: 90vw;\n        }\n    }\n    \n    .passwordRemind {\n        text-align: right;\n        color: #9495A9;\n        font-weight: 400;\n        font-size: 14px;\n    }\n    \n    .buttonContainer {\n        display: flex;\n        flex-direction: column;\n        row-gap: 1rem;\n        padding: 2rem 0;\n        \n        .accountText {\n            color: #323352;\n            font-weight: 400;\n            font-size: 16px;\n            \n            b {\n                font-weight: 700;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
