import {Box, Button, Flex, HStack, Image, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter} from "@chakra-ui/react";
import shopMockup from "../../../assets/images/shop_mockup.png";
import {useEffect, useMemo, useState} from "react";
import {FiClock} from "react-icons/fi";
import {GoPlus} from "react-icons/go";
import CanisterIcon from "../../../assets/icons/CanisterIcon";
import {BASE_URL} from "../../../config";
import carLogos from "../../../assets/images/carbrands";
import moment from "moment";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {useAuth} from "../../../providers/AuthProvider";
import {useNavigate} from "react-router-dom";
import TransactionsService from "../../../api/services/TransactionsService";

const MyOfferListItem = ({offer}) => {
    const {user} = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isHovering, setHovering] = useState(false);
    const navigate = useNavigate();

    const brandLogo = useMemo(() => {
        console.log('[OfferListItem] 🚀 ', JSON.stringify(offer, null, 2));
        return carLogos[offer.client.carBrand];
    }, [offer]);

    const expirationTime = useMemo(() => {
        return offer.noExpirationDate ? "odwołania" : moment(offer.expirationDateUtc).format('DD.MM.YYYY');
    }, [offer]);

    const handleMouseEnter = () => {
        setHovering(true);
    };

    const handleMouseLeave = () => {
        setHovering(false);
    };

    const handleNavigate = (route) => {
        navigate(route);
    };

    const handleLogin = () => {
        handleNavigate('/login');
        onOpen();
    };

    const handleRealize = () => {
        TransactionsService.realize(offer?.id).then(response => {
            if (response.data.isSuccess) {
                navigate('/my?index=1');
                onClose();
            }
        })
    }

    const OfferDetails = ({ minimalPrice, litersAward }) => (
        <HStack w={'100%'} justifyContent={'center'} alignItems={'center'} spacing={0} mb={5}>
            <Flex borderWidth={2} borderRadius={'full'} borderColor={'primary'} flex={1} py={1} alignItems={'center'}
                  justifyContent={'center'} fontWeight={500} fontSize={16}>
                {minimalPrice} zł
            </Flex>
            <Flex flex={{ base: 0.6, sm: 1 }} alignItems={'center'} justifyContent={'center'}>
                <Box h={'2px'} bg={'primary'} w={'100%'}/>
                <Box borderWidth={2} p={2} alignItems={'center'} justifyContent={'center'} borderRadius={'full'} borderColor={'primary'}>
                    <GoPlus size={16} strokeWidth={0.8}/>
                </Box>
                <Box h={'2px'} bg={'primary'} w={'100%'}/>
            </Flex>
            <Flex borderWidth={2} borderRadius={'full'} borderColor={'primary'} flex={1} py={1} columnGap={1} alignItems={'center'}
                  justifyContent={'center'} fontWeight={500} fontSize={16}>
                <CanisterIcon boxSize={3}/>
                {litersAward} litrów
            </Flex>
        </HStack>
    );

    return (
        <>
            <Flex bg={'white'} p={4} borderRadius="12px" w={{base: '100%', sm: '32%'}} justifyContent={'center'}
                flexDirection={'column'} alignItems={'center'} style={{
                position: 'relative',
                top: isHovering ? -6 : '0px',
                transition: 'top ease 0.5s',
                borderBottom: '1px solid var(--Text-secondary, #9495A9)'
            }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} rowGap={3}>
                <Flex position={'relative'} w={'100%'} justifyContent={'center'}>
                    <Box position={'absolute'} left={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                        <Image src={shopMockup} objectFit={'contain'}/>
                    </Box>
                    <Box position={'absolute'} right={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                        <Image src={brandLogo} objectFit={'contain'}/>
                    </Box>
                    <Image src={`${BASE_URL}/${offer.imageUri}`} w={'90%'} aspectRatio={16/9} objectFit={'contain'} borderRadius={6} />
                </Flex>

                <OfferDetails minimalPrice={offer.minimalPrice} litersAward={offer.litersAward} />

                <Flex color={'primary'} flexDirection={'column'} alignItems={'center'} py={2}>
                    <Text fontWeight={700} fontSize={14}>{offer.name}</Text>
                    <Text color={'primary'} fontWeight={400} fontSize={13}>{offer.client.companyName}</Text>
                </Flex>

                <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap="12px">
                    <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} px={14} fontSize={16}
                            fontWeight={700} bg={'green'} borderRadius={32} onClick={onOpen}>
                            <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 8.6L6.28571 13L17 2" stroke="#323352" stroke-width="2.8" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <Text px={1}>Zrealizuj</Text>
                    </Button>
                    <Flex alignItems={'center'} gap={1} color={'gray.400'} fontWeight={400} fontSize={13}>
                        <FiClock/>
                        Oferta ważna do {expirationTime}
                    </Flex>
                </Flex>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size="md">
                <ModalOverlay />
                <ModalContent borderRadius="16px" mx={{ base: 2, md: 0 }} px={{ base: 1, md: 0 }}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex position={'relative'} w={'100%'} justifyContent={'center'}>
                            <Box position={'absolute'} left={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                                <Image src={shopMockup} objectFit={'contain'}/>
                            </Box>
                            <Box position={'absolute'} right={0} top={1} bg={"white"} borderRadius={4} p={1} w={'40px'} h={'40px'}>
                                <Image src={brandLogo} objectFit={'contain'}/>
                            </Box>
                            <Image src={`${BASE_URL}/${offer.imageUri}`} w={'90%'} aspectRatio={16/9} bjectFit={'contain'} borderRadius={6} />
                        </Flex>
                        <OfferDetails minimalPrice={offer.minimalPrice} litersAward={offer.litersAward} />
                        <Flex color={'primary'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Text fontWeight={700} fontSize={14}>{offer.name}</Text>
                            <Text color={'primary'} fontWeight={400} fontSize={13}>{offer.client.companyName}</Text>
                            <Flex color="#9495A9" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Flex gap="6px">
                                    <HiOutlineLocationMarker/>
                                    <Text fontWeight={400} fontSize={13}>
                                        {offer.client.address.street},  {offer.client.address.postalCode}  {offer.client.address.city}
                                    </Text>
                                </Flex>
                                <Flex gap="6px" alignItems={'center'} justifyContent={'center'} pt="24px">
                                    <Text fontWeight={400} fontSize={15} align={"center"} lineHeight="24px">
                                        {offer.description}
                                    </Text>
                                </Flex>
                            </Flex>

                        </Flex>
                    </ModalBody>
                    <ModalFooter display="flex" flexDirection="column" alignItems="center" w="100%">
                        <Flex fontSize={13} color="#9495A9" mb={4} alignItems={'center'} gap={1}>
                            <FiClock />
                            Oferta ważna do {expirationTime}
                        </Flex>
                            <Button color="primary" variant="solid" _hover={{bg: 'green.500'}} py={6} px={14} fontSize={16} fontWeight={700} mt={3}
                                    bg={'green'} borderRadius={32} w={'100%'} onClick={handleRealize}>
                                Zrealizuj
                            </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MyOfferListItem;
