// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Ubuntu-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Ubuntu-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Ubuntu-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Ubuntu-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Ubuntu-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Ubuntu-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Ubuntu-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Ubuntu-MediumItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype');
    font-weight: 500;
    font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+DAAmD;IACnD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAgD;IAChD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAkD;IAClD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAsD;IACtD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAiD;IACjD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAuD;IACvD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAkD;IAClD,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,+DAAwD;IACxD,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-Bold.ttf') format('truetype');\n    font-weight: 700;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-Italic.ttf') format('truetype');\n    font-weight: 400;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-BoldItalic.ttf') format('truetype');\n    font-weight: 700;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-Light.ttf') format('truetype');\n    font-weight: 300;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-LightItalic.ttf') format('truetype');\n    font-weight: 300;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-Medium.ttf') format('truetype');\n    font-weight: 500;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Ubuntu';\n    src: url('./Ubuntu-MediumItalic.ttf') format('truetype');\n    font-weight: 500;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
