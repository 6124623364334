import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";

const theme = extendTheme({
    colors,
    fonts: {
        heading: "'Ubuntu', sans-serif",
        body: "'Ubuntu', sans-serif",
    },
});

export default theme;



