import {Link as ReactRouterLink, useLocation} from 'react-router-dom'
import {Link as ChakraLink} from '@chakra-ui/react'
import {useMemo} from "react";

const LinkTo = ({address, children, active, ...props}) => {
    const location = useLocation();

    const isActive = useMemo(() => {
        return location.pathname === address && active;
    }, [location.pathname, active]);
    
    return (
        <ChakraLink as={ReactRouterLink} to={address} textDecoration='underline' textDecorationThickness={2} {...props}>
            {children}
        </ChakraLink>
    )
};

export default LinkTo;