// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
  display: flex;
  background-color: #011938;
  padding: 3rem 0 3rem 0;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
}

.container {
  display: flex;
  width: 1312px;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: white;
    text-align: left;
  }
  
  ul li {
    padding: 8px 0 8px 0;
  }

  ul li a {
    text-decoration: none;
    color: #FFF;
  }

  ul li:first-child {
    color: #496C99;
  }
}

.contactRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.greenText {
  color: #2FEA52;
}

.whiteText {
  color: #FFF;
}

.socialRow {
  border-top: #496C99 solid 1px;
  padding: 2rem 0 0 0;
  color: #FFF
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  color: #2FEA52;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;;EAEb;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".mainContainer {\n  display: flex;\n  background-color: #011938;\n  padding: 3rem 0 3rem 0;\n  align-items: center;\n  flex-direction: column;\n  row-gap: 2rem;\n}\n\n.container {\n  display: flex;\n  width: 1312px;\n  justify-content: space-between;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  row-gap: 1rem;\n\n  ul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n    color: white;\n    text-align: left;\n  }\n  \n  ul li {\n    padding: 8px 0 8px 0;\n  }\n\n  ul li a {\n    text-decoration: none;\n    color: #FFF;\n  }\n\n  ul li:first-child {\n    color: #496C99;\n  }\n}\n\n.contactRow {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  column-gap: 1rem;\n}\n\n.greenText {\n  color: #2FEA52;\n}\n\n.whiteText {\n  color: #FFF;\n}\n\n.socialRow {\n  border-top: #496C99 solid 1px;\n  padding: 2rem 0 0 0;\n  color: #FFF\n}\n\n.socials {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  column-gap: 1rem;\n  color: #2FEA52;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
